export function getPageQuery(path: string) {
  return gql`
query getPage {
   pageBy(uri: "${path}") {
    content
    title
    isPostsPage
    seo {
        canonical
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        title
        twitterTitle
        twitterDescription
        opengraphType
        opengraphTitle
        opengraphSiteName
        opengraphPublisher
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphUrl
        opengraphDescription
        opengraphAuthor
        twitterImage {
          sourceUrl
        } 
        opengraphImage {
          sourceUrl
        } 
      }
    children {
      edges {
        node {
          slug
          ... on Page {
            id
            title
            uri
            pageMeta {
              pageNavLinkDescription
              id
            }
            featuredIllustration {
              illustration {
                sourceUrl
              }
            }
          }
        }
      }
    }
    relatedPages {
      manualLinks {
        ... on Page {
          id
          featuredIllustration {
            illustration {
              sourceUrl
            }
          }
         pageMeta {
            pageNavLinkDescription
            id
          }
          title
          uri
        }
      }
      showRelatedPages
    }
    pageBanner {
      bannerImage {
        sourceUrl
      }
    }
    pageMeta {
      id
      pageBackgroundColour
      pageHero {
        fieldGroupName
        headline
        lead
         button {
          target
          title
          url
        }
      }
      pageComponents {
        ... on Page_Pagemeta_PageComponents_HeadlineText {
          fieldGroupName
          headline
          text
          layout
        }
        ... on Page_Pagemeta_PageComponents_OurTeam {
          fieldGroupName
          lead
          title
        }
         ... on Page_Pagemeta_PageComponents_ContactForm {
          fieldGroupName
          headline
          text
          formId
          image {
            sourceUrl
          }
        }
        ... on Page_Pagemeta_PageComponents_ContentWithImage {
          fieldGroupName
          images {
            altText
            sourceUrl
          }
          content {
            headline
            tag
            text
            fieldGroupName
            align
            imageFit,
            primaryLink {
              url
              title
              target
            }
            secondaryLink {
              url
              title
              target
            }
            style
          }
        }
        ... on Page_Pagemeta_PageComponents_PhoneMockupShowcase {
          fieldGroupName
          headline
          mockupShowcase {
            mockupImage {
              altText
              sourceUrl(size: LARGE)
              srcSet(size: LARGE)
            }
            text
          }
        }
         ... on Page_Pagemeta_PageComponents_Faqs {
          fieldGroupName
        }
        ... on Page_Pagemeta_PageComponents_PulloutText {
          fieldGroupName
          text
        }
        ... on Page_Pagemeta_PageComponents_RandomQuote {
          fieldGroupName
        }
        ... on Page_Pagemeta_PageComponents_Callout {
          fieldGroupName
          header
          subHeader
          text
          illustration {
            altText
            sourceUrl
          }
          button {
            title
            url
            target
          }
        }
        ... on Page_Pagemeta_PageComponents_PageLinks {
          fieldGroupName
          pageLinks {
            ... on Page {
              id
              title
              uri
              pageMeta {
                id
                pageNavLinkDescription
              }
              featuredImage {
                node {
                  altText
                  sourceUrl
                }
              }
            }
          }
        }
        ... on Page_Pagemeta_PageComponents_PageLinkSlider {
          fieldGroupName
          pageLinks {
            ... on Page {
              id
              featuredImage {
                node {
                  altText
                  sourceUrl
                }
              }
              title
              uri
              pageMeta {
                id
                pageSliderHeadline
              }
            }
          }
        }
         ... on Page_Pagemeta_PageComponents_HeadlineWithButton {
          fieldGroupName
          headline
          button {
            target
            title
            url
          }
        }
        ... on Page_Pagemeta_PageComponents_LatestResources {
          count
          fieldGroupName
          showFeaturedResource
          showLatestTitle
          showViewMoreButton
        }
        ... on Page_Pagemeta_PageComponents_Seperator {
          fieldGroupName
        }
        ... on Page_Pagemeta_PageComponents_DividerImages {
          fieldGroupName
          images {
            altText
            sourceUrl
          }
        }
      }
    }
  }
}
`
}
