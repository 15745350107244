<template>
  <div class="min-h-full" :class="pageBgColour === 'Grey' ? 'bg-grey' : 'bg-white'">
    <PageHeader/>

    <slot name="pageHero" :hero-data="pageHeroData"></slot>

    <PageBanner v-if="pageBannerData?.sourceUrl" :banner="pageBannerData"/>

    <main ref="mainRef" class="h-full mx-auto pt-[20px] md:pt-[40px] page-content">
      <slot :page-components="pageComponentsData"
            :page-children="pageChildren"
            :related-pages="relatedPages"
            :is-posts-page="isPostsPage"
      ></slot>
    </main>

    <PageFooter/>
  </div>
</template>

<script setup lang="ts">
import PageHeader from "~/layouts/PageHeaderLayout.vue";
import PageFooter from "~/layouts/PageFooterLayout.vue";

import { getPageQuery } from "~/graphql/queries/getPage";
import PageBanner from "~/components/pages/banner/PageBanner.vue";

const pageBgColour = ref(null);
const pageHeroData = ref({});
const pageComponentsData = ref([]);
const pageChildren = ref([]);
const isPostsPage = ref(null);
const relatedPages = ref([]);
const seo = ref({});

const route = useRoute();
const query = getPageQuery(route.path);
const { data } = await useAsyncQuery(query);

if (data.value?.pageBy === null) {
  throw createError({
    statusCode: 404,
    statusMessage: 'Page Not Found',
  })
}

const pageBannerData = data?.value?.pageBy?.pageBanner?.bannerImage;

isPostsPage.value = data?.value?.pageBy?.isPostsPage ?? false;

if (data.value?.pageBy?.children) {
  pageChildren.value = data.value.pageBy.children.edges;
}

if (data.value?.pageBy?.relatedPages) {
  relatedPages.value = data.value.pageBy.relatedPages;
}

if (data.value?.pageBy?.pageMeta) {
  const {pageHero, pageBackgroundColour, pageComponents} = data.value.pageBy?.pageMeta;

  pageHeroData.value = {
    headline: pageHero.headline,
    lead: pageHero.lead,
    button: pageHero.button
  }

  pageComponentsData.value = pageComponents;

  pageBgColour.value = pageBackgroundColour;
}

if (data?.value?.pageBy?.seo) {
  seo.value = data.value.pageBy.seo;

  const robots = [seo.value.metaRobotsNofollow, seo.value.metaRobotsNoindex];

  useHead({
    title: seo.value.title,
    htmlAttrs: {
      lang: 'en',
      class: 'page-layout'
    },
    link: [
      {
        rel: 'apple-touch-icon',
        sizes: "180x180",
        href: '/apple-touch-icon.png'
      },
      {
        rel: 'icon',
        sizes: "32x32",
        href: '/favicon-32x32.png'
      },
      {
        rel: 'icon',
        sizes: "16x16",
        href: '/favicon-16x16.png'
      },
      {
        rel: 'manifest',
        href: '/site.webmanifest'
      },
    ],
    meta: [
      { name: 'description', content: seo.value.metaDesc },
      { property: 'og:locale', content: 'en_GB' },
      { property: 'og:type', content: seo.value.opengraphType },
      { property: 'og:title', content: seo.value.opengraphTitle },
      { property: 'og:description', content: seo.value.opengraphDescription },
      { property: 'og:url', content: seo.value.opengraphUrl },
      { property: 'og:site_name', content: seo.value.opengraphSiteName },
      { property: 'og:image', content: seo.value.opengraphImage?.sourceUrl ?? '' },
      { property: 'article:modified_time', content: seo.value.opengraphModifiedTime },
      { property: 'twitter:title', content: seo.value.twitterTitle },
      { property: 'twitter:description', content: seo.value.twitterDescription },
      { property: 'twitter:image', content: seo.value.twitterImage?.sourceUrl ?? '' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'robots', content: robots.join(',') }, // Added robots meta tag
    ],
  });
}
</script>
